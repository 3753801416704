import React from 'react';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Form from '../components/Form';
import Prompt from '../components/Prompt';
import useAuth from '../hooks/useAuth';

const FormPage = () => {
  const [isAuth, setAuth] = useAuth();
  return (
    <Layout hideFooter>
      <SEO title="Submit your challenge" />
      {isAuth ? (
        <Form dataUrl="https://fmoxcelerate.typeform.com/to/elzkNT" />
      ) : (
        <Prompt setAuth={setAuth} />
      )}
    </Layout>
  );
};

export default FormPage;
